import React from 'react';
import ReactDOM from 'react-dom';

if (!process.env.REACT_APP_BUILD_TARGET) {
  throw new Error(`A build target must be specified.`);
}

import(`./apps/${process.env.REACT_APP_BUILD_TARGET}`).then(({ default: App }) => (
  ReactDOM.render(
    <App />,
    document.getElementById('root'),
  )
));
